/**
 * Created by LMO on 11/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */

import Component from '../../../assets/scripts/modules/component'
import findParentByClassName from '../../../assets/scripts/utilities/find-parent-by-classname'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_CHAPTERED_VIDEO_SKIP_TO } from '../../organisms/chaptered-video/chaptered-video'

export default class VideoChapter extends Component {
  getParentInstance () {
    // Dynamic parent getting because the parent might not be initialized yet
    return findParentByClassName(this.element, 'chaptered-video').instance
  }

  init () {
    this.hitTarget = this.element.querySelector('.video-chapter__hit-target')
    this.value = Number(this.element.getAttribute('data-start-at') || 0)

    this.initHover()
    this.initClick()
  }

  initClick () {
    const instance = this.getParentInstance()

    this.hitTarget.addEventListener('click', (event) => {
      event.preventDefault()
      fireCustomEvent(EVENT_CHAPTERED_VIDEO_SKIP_TO, { instance, value: this.value })
    })
  }

  initHover () {
    this.hitTarget.addEventListener('mouseover', (event) => {
      this.element.classList.add('video-chapter--show-popup')
    })

    this.hitTarget.addEventListener('mouseout', (event) => {
      this.element.classList.remove('video-chapter--show-popup')
    })
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.video-chapter').forEach(
    element => { element.instance = element.instance || new VideoChapter(element) })
)
