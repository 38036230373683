
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_MUTE_ALL_VIDEOS, EVENT_REQUEST_MUTED_STATUS, EVENT_UNMUTE_ALL_VIDEOS } from '../sound-button/sound-button'

export const EVENT_VIDEO_PAUSED = 'event-video-paused'
export const EVENT_VIDEO_PLAYING = 'event-video-playing'
export const EVENT_REQUEST_PLAY = 'event-request-play'

class VideoComponent extends Component {
  init () {
    this.video = this.element.querySelector('.video__video')
    this.hitTarget = this.element.querySelector('.video__hit-target')
    this.mobileNative = this.element.hasAttribute('data-mobile-native')
    // For now we assume all videos that start muted explicitly can not unmute via the unmute event
    this.canToggleMute = !this.video.hasAttribute('muted') || this.video.getAttribute('muted') === 'false'

    if (this.hitTarget && !this.mobileNative) {
      this.hitTarget.addEventListener('click', this.onHitTargetClick.bind(this))
    }

    this.video.addEventListener('click', this.onHitTargetClick.bind(this))

    this.video.addEventListener('play', () => this.setClassesPlaying())
    this.video.addEventListener('pause', () => this.setClassesPaused())

    if (this.canToggleMute) {
      window.addEventListener(EVENT_MUTE_ALL_VIDEOS, () => this.muteVideo())
      window.addEventListener(EVENT_UNMUTE_ALL_VIDEOS, () => this.unMuteVideo())
      fireCustomEvent(EVENT_REQUEST_MUTED_STATUS)
    }

    this.video.addEventListener('canplaythrough', () => this.setClassesLoaded())

    this.autoplay = this.video.getAttribute('autoplay') !== null

    if (this.autoplay && (window.matchMedia('(min-width: 700px)').matches || !this.mobileNative)) {
      this.setClassesPlaying()

      if (this.canToggleMute) {
        // Since this will be muted; we will mute everything, so the user gets a trigger to unmute
        // fireCustomEvent(EVENT_MUTE_ALL_VIDEOS)
      }
    }

    this.initOnPausing()
    this.initOnPlaying()
    this.initRequestPlayListening()
  }

  initRequestPlayListening () {
    // Other components can request the video to play, instead of immediately playing it
    let canPlay = false
    let playWhenCan = false
    const toPlay = (allowMuted = true) => {
      this.setClassesPlaying()
      this.video.play().catch((reason) => {
        console.log(`couldnt start video: ${reason}`)
      })
    }
    const listener = () => {
      canPlay = true
      window.removeEventListener('canplay', listener)
      window.removeEventListener('timeupdate', listener)

      if (playWhenCan) {
        toPlay()
      }
    }
    window.addEventListener(EVENT_REQUEST_PLAY, (event) => {
      if (event.detail && event.detail.video === this.video) {
        if (canPlay) {
          toPlay(event.detail.allowMuted)
        } else {
          playWhenCan = true
        }
      }
    })
    this.video.addEventListener('canplay', listener)
    this.video.addEventListener('timeupdate', listener)
  }

  muteVideo () {
    this.video.muted = true
  }

  unMuteVideo () {
    this.video.muted = false
  }

  initOnPausing () {
    this.video.addEventListener('pause', () => fireCustomEvent(EVENT_VIDEO_PAUSED))
  }

  initOnPlaying () {
    this.video.addEventListener('play', () => fireCustomEvent(EVENT_VIDEO_PLAYING))
  }

  setClassesPlaying () {
    this.element.classList.remove('video--paused')
    this.element.classList.add('video--playing')
  }

  setClassesPaused () {
    this.element.classList.remove('video--playing')
    this.element.classList.add('video--paused')
  }

  setClassesLoaded () {
    this.element.classList.add('video--loaded')
  }

  onHitTargetClick (event) {
    event.preventDefault()

    if (this.video.currentTime === 0 || this.video.paused || this.video.ended) {
      this.video.play()
      console.log('hit target click!')
    } else {
      this.video.pause()
      console.log('hit target click but pause!')
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.video').forEach(element => {
  element.instance = element.instance || new VideoComponent(element)
}))
