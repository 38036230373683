/**
 * Created by LMO on 11/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */

import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_ICON_BUTTON_HOOK_WHEN_CHILD } from '../../atoms/icon-button/icon-button'
import { EVENT_REQUEST_PLAY, EVENT_VIDEO_PAUSED, EVENT_VIDEO_PLAYING } from '../../atoms/video/video'

export const EVENT_CHAPTERED_VIDEO_SKIP_TO = 'event-chaptered-video-skip-to'
export const EVENT_CHAPTERED_VIDEO_TIMER_UPDATE = 'event-chaptered-video-timer-update'

const DELAY_TO_NEXT_VIDEO = 10 // seconds

const STATE_VIDEO = 'video'
const STATE_UPNEXT = 'upnext'

function formatTime (time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time - minutes * 60)
  const secondValue = seconds < 10 ? `0${seconds}` : seconds
  return `${minutes}:${secondValue}`
}

export default class ChapteredVideo extends Component {
  init () {
    this.videoElement = this.element.querySelector('.chaptered-video__video video')
    this.state = STATE_VIDEO

    this.initAutoplayListening()
    this.initCanplayListening()
    this.initEndState()
    this.initEndStateListening()
    this.initPauseButtonListening()
    this.initPlayButtonListening()
    this.initPlayingListening()
    this.initPausingListening()
    this.initProgress()
    this.initProgressClicking()
    this.initProgressListening()
    this.initScrollListening()
    this.initSkipListening()
    this.initUpNextCancelListening()

    this.videoElement.addEventListener('loadedmetadata', () => {
      this.initProgress()
    })
  }

  clearEndState () {
    if (this.endStateChecker) {
      clearInterval(this.endStateChecker)
    }

    if (this.endStateTimer) {
      clearTimeout(this.endStateTimer)
    }

    this.element.classList.remove('chaptered-video--video-finished')
    this.state = STATE_VIDEO
  }

  initAutoplayListening () {
    // updates state if autoplay is detected
    const listener = () => {
      if (window.scrollY <= 0 && window.matchMedia('(min-width: 700px)').matches) {
        this.play()
      } else {
        this.pause()
      }

      this.videoElement.removeEventListener('timeupdate', listener)
    }

    this.videoElement.addEventListener('timeupdate', listener)
  }

  initMobileScreenshot () {

  }

  initCanplayListening () {
    // Play the video when it can play, one time
    let didPlay = false

    const listener1 = () => {
      didPlay = true

      this.videoElement.removeEventListener('timeupdate', listener1)
    }

    this.videoElement.addEventListener('timeupdate', listener1)

    const listener2 = () => {
      if (window.scrollY <= 0 && didPlay) {
        this.reset()
        this.play()
      }

      this.videoElement.removeEventListener('canplay', listener2)
    }

    this.videoElement.addEventListener('canplay', listener2)
  }

  initEndState () {
    this.endStateTimer = null
    this.endStateChecker = null
    this.endStateEndTime = null
  }

  initEndStateListening () {
    this.videoElement.addEventListener('timeupdate', () => {
      if (this.videoElement.ended) {
        this.startEndState()
      }
    })
  }

  initPauseButtonListening () {
    const pauseElement = this.element.querySelector('.chaptered-video__video-pause')

    pauseElement.addEventListener('click', (event) => {
      event.preventDefault()
      this.pause()
    })
  }

  initPausingListening () {
    window.addEventListener(EVENT_VIDEO_PAUSED, () => {
      this.pause()
    })
  }

  initPlayButtonListening () {
    const playElement = this.element.querySelector('.chaptered-video__video-play')

    playElement.addEventListener('click', (event) => {
      event.preventDefault()
      this.play()
    })
  }

  initPlayingListening () {
    window.addEventListener(EVENT_VIDEO_PLAYING, () => {
      this.play()
    })
  }

  initProgress () {
    const progressDurationDisplayElement = this.element.querySelector('.chaptered-video__duration')
    this.progressTimeElement = this.element.querySelector('.chaptered-video__progress-bar-progress')
    this.progressTimeDisplayElement = this.element.querySelector('.chaptered-video__current-time')

    progressDurationDisplayElement.innerHTML = formatTime(this.videoElement.duration)
    this.setProgress(0)
  }

  initProgressClicking () {
    this.progressElement = this.element.querySelector('.chaptered-video__progress-bar')

    if (!this.progressElement) {
      return
    }

    this.progressElement.addEventListener('click', event => {
      const box = this.progressElement.getBoundingClientRect()
      const mouseX = event.clientX
      let percentage = (mouseX - box.left) / box.width
      percentage = Math.max(0, Math.min(1, percentage))
      this.seekTo(Math.floor(this.videoElement.duration * percentage))
    })
  }

  initProgressListening () {
    this.videoElement.addEventListener('timeupdate', () => {
      this.setProgress()
    })
  }

  initScrollListening () {
    window.addEventListener('scroll', () => {
      if (this.state === STATE_VIDEO) {
        if (window.scrollY <= 0) {
          this.play()
        } else if (!this.videoElement.paused) {
          this.pause()
        }
      }
    })
  }

  initSkipListening () {
    window.addEventListener(EVENT_CHAPTERED_VIDEO_SKIP_TO, (event) => {
      const { instance, value } = event.detail

      if (instance === this) {
        this.seekTo(value)
      }
    })
  }

  initUpNextCancelListening () {
    const cancelElement = this.element.querySelector('.chaptered-video__up-next-cancel')

    cancelElement.addEventListener('click', (event) => {
      event.preventDefault()

      this.reset()
    })
  }

  pause () {
    this.element.classList.remove('chaptered-video--video-playing')

    if (!this.videoElement.paused) {
      this.videoElement.pause()
    }
  }

  play () {
    this.element.classList.add('chaptered-video--video-playing')

    if (this.videoElement.paused) {
      fireCustomEvent(EVENT_REQUEST_PLAY, {
        video: this.videoElement,
        allowMuted: true
      })
    }
  }

  reset () {
    this.clearEndState()
    this.seekTo(0)
    this.pause()
  }

  seekTo (seconds) {
    this.videoElement.currentTime = seconds
    this.setProgress()
  }

  setProgress (value = null) {
    const ratio = value === null ? this.videoElement.currentTime / this.videoElement.duration : value
    const percentage = Math.round(ratio * 100)
    this.progressTimeElement.style.width = `${percentage}%`
    this.progressTimeDisplayElement.innerHTML = formatTime(this.videoElement.currentTime)
  }

  startEndState () {
    const nextItemElement = this.element.querySelector('.chaptered-video__up-next-play')
    const nextItemElementHref = nextItemElement ? nextItemElement.getAttribute('href') : ''

    this.progressTimeElement = this.element.querySelector('.chaptered-video__progress-bar-progress')
    this.progressTimeDisplayElement = this.element.querySelector('.chaptered-video__current-time')

    if (nextItemElementHref) {
      fireCustomEvent(
        EVENT_ICON_BUTTON_HOOK_WHEN_CHILD,
        { className: 'chaptered-video', eventName: EVENT_CHAPTERED_VIDEO_TIMER_UPDATE, instance: this }
      )

      this.clearEndState()
      this.element.classList.add('chaptered-video--video-finished')
      this.endStateEndTime = new Date() * 1 + DELAY_TO_NEXT_VIDEO * 1000
      this.endStateTimer = setInterval(() => { /* eslint-disable-line smells/no-setinterval */
        // Simple "pause" solution; as long as you're scrolled down, the timer resets
        if (window.scrollY <= 0) {
          // update progress
          const progress = 1 - (this.endStateEndTime - new Date() * 1) / DELAY_TO_NEXT_VIDEO / 1000
          fireCustomEvent(EVENT_CHAPTERED_VIDEO_TIMER_UPDATE, { instance: this, value: progress })

          if (progress >= 1) {
            this.clearEndState()
            window.location.href = nextItemElementHref
          }
        } else {
          this.endStateEndTime = new Date() * 1 + DELAY_TO_NEXT_VIDEO * 1000
        }
      }, 50)
    } else {
      this.reset()
    }

    this.state = STATE_UPNEXT
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.chaptered-video').forEach(
    element => { element.instance = element.instance || new ChapteredVideo(element) })
)
