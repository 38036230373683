
import Component from '../../../assets/scripts/modules/component'

export default class ScrollDownComponent extends Component {
  init () {
    this.element.addEventListener('click', event => {
      const target = document.getElementById(this.element.href.split('#')[1])

      if (target) {
        target.scrollIntoView()
      }
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.scroll-down').forEach(element => {
  element.instance = element.instance || new ScrollDownComponent(element)
}))
