/**
 * Created by LMO on 14/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */

import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_OPEN_VALUE_CHAIN, EVENT_VALUE_CHAIN_CLOSED, EVENT_VALUE_CHAIN_OPENED } from '../value-chain/value-chain'

export default class ValueChainOpener extends Component {
  init () {
    this.initOpenListening()
    this.initOpenedClosedListening()
  }

  initOpenListening () {
    this.element.addEventListener('click', (event) => {
      event.preventDefault()
      fireCustomEvent(EVENT_OPEN_VALUE_CHAIN)
    })
  }

  initOpenedClosedListening () {
    window.addEventListener(EVENT_VALUE_CHAIN_OPENED, (event) => {
      this.element.classList.add('value-chain-opener--value-chain-open')
    })
    window.addEventListener(EVENT_VALUE_CHAIN_CLOSED, (event) => {
      this.element.classList.remove('value-chain-opener--value-chain-open')
    })
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.value-chain-opener').forEach(
    element => { element.instance = element.instance || new ValueChainOpener(element) })
)
