
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_MENU_OVERLAY_OPEN } from '../../structures/menu-overlay/menu-overlay'

export const EVENT_REGISTER_CLOSABLE = 'event-register-closable'

export default class MenuBarComponent extends Component {
  init () {
    this.initMenuLink()
    this.initRegisterClosable()
  }

  initMenuLink () {
    const link = this.element.querySelector('.menu-bar__menu-link')

    if (link) {
      link.addEventListener('click', () => fireCustomEvent(EVENT_MENU_OVERLAY_OPEN))
    }
  }

  initRegisterClosable () {
    // A callback can be registered, which will show a close button, calling the close button
    const closer = this.element.querySelector('.menu-bar__close-link')
    const closerStack = []

    if (closer) {
      window.addEventListener(EVENT_REGISTER_CLOSABLE, (event) => {
        closerStack.push(event.detail.callback)
        this.element.classList.add('menu-bar--show-closer')
      })
      closer.addEventListener('click', () => {
        if (closerStack.length) {
          closerStack.pop()()
        }
        if (!closerStack.length) {
          this.element.classList.remove('menu-bar--show-closer')
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-bar').forEach(element => {
  element.instance = element.instance || new MenuBarComponent(element)
}))
