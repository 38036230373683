
import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  navigation: {
    nextEl: '.article-photo-carousel__navigation .icon-button:nth-child(2)',
    prevEl: '.article-photo-carousel__navigation .icon-button:first-child'
  },
  spaceBetween: 20
}

export default class ArticlePhotoCarouselComponent extends Component {
  init () {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.article-photo-carousel').forEach(element => {
  element.instance = element.instance || new ArticlePhotoCarouselComponent(element)
}))
