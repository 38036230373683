
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_STOP_MARQUEE } from '../../molecules/marquee-bar/marquee-bar'
import { EVENT_MICRIO_HIDE, EVENT_MICRIO_SHOW } from '../../organisms/micrio/micrio'

export const EVENT_INTRO_OVERLAY_HIDE = 'intro-overlay-hide'

export default class IntroOverlayComponent extends Component {
  init () {
    this.introVideo = null
    this.mainVideo = null
    this.skip = this.element.classList.contains('intro-overlay--visible')

    this.initializeIntroVideo()
    this.initializeMainVideo()
    this.initCloseIntroButton()
    this.initProgressBar()
    this.initStartOverlayFromHTML()
    this.initStartMovieButton()
    this.initSkipMovieLink()
  }

  close (instant) {
    this.element.classList.remove('intro-overlay--visible')
    this.introVideo.pause()

    if (!this.noMainVideo) {
      this.mainVideo.pause()
    }

    if (instant) {
      this.element.classList.add('intro-overlay--hide')
    }

    fireCustomEvent(EVENT_MICRIO_SHOW)
  }

  initializeIntroVideo () {
    this.introVideo = this.element.querySelector('.intro-overlay__intro-video video')
  }

  initializeMainVideo () {
    if (!this.mainVideo) {
      this.mainVideo = this.element.querySelector('.intro-overlay__main-video video')
      this.noMainVideo = !this.mainVideo

      if (this.noMainVideo) {
        return
      }

      this.mainVideo.addEventListener('timeupdate', () => {
        if ((this.mainVideo.duration - this.mainVideo.currentTime) <= 3) {
          this.close()
        }
      })
    }
  }

  initCloseIntroButton () {
    const button = this.element.querySelector('.intro-overlay__close-intro')
    button.addEventListener('click', (event) => {
      this.close()
    })
  }

  initSkipMovieLink () {
    const link = this.element.querySelector('.intro-overlay__skip-movie')

    link.addEventListener('click', (event) => {
      event.preventDefault()
      this.close()
    })
  }

  initStartMovieButton () {
    const button = this.element.querySelector('.intro-overlay__start-movie')
    button.addEventListener('click', (event) => {
      this.startMovieState()
      console.log('Bug fix to make sure image loads...')
      document.querySelector('micr-io').micrio.play()
      document.querySelector('micr-io').micrio.modules.navigator.goto('HDxbo').then(() => {
        document.querySelector('micr-io').micrio.modules.navigator.goto('qMbPH')
      })
    })
  }

  initStartOverlayFromHTML () {
    if (!this.introVideo) {
      this.initializeIntroVideo()
    }

    const shouldShow = this.element.classList.contains('intro-overlay--visible')

    if (shouldShow) {
      this.start()
    } else {
      this.close()
    }
  }

  initProgressBar () {
    if (!this.mainVideo) {
      this.initializeMainVideo()
    }

    if (this.noMainVideo) {
      return
    }

    const progressBar = this.element.querySelector('.intro-overlay__progress-bar')
    progressBar.setAttribute('max', this.mainVideo.duration)

    this.mainVideo.addEventListener('canplay', () => {
      progressBar.setAttribute('max', this.mainVideo.duration)
    })

    this.mainVideo.addEventListener('timeupdate', () => {
      progressBar.setAttribute('value', this.mainVideo.currentTime)
    })
  }

  open () {
    this.element.classList.add('intro-overlay--visible')
  }

  reset () {
    this.element.classList.remove('intro-overlay--movie')
  }

  start () {
    fireCustomEvent(EVENT_MICRIO_HIDE)
    window.micrioPleaseHide = true
  }

  startMovieState () {
    // Wait for the intro video to finish
    const introEnded = new Promise((resolve, reject) => {
      if (this.introVideo.currentTime > 0) {
        this.introVideo.addEventListener('timeupdate', () => {
          if (this.introVideo.ended) {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })

    const fContinue = () => {
      if (this.noMainVideo) {
        this.close()
        return
      }

      fireCustomEvent(EVENT_STOP_MARQUEE)
      this.element.classList.add('intro-overlay--state-movie')
      this.mainVideo.play()
    }

    introEnded.then(fContinue).catch(fContinue)

    this.element.classList.add('intro-overlay--fade-header')
    this.introVideo.loop = false
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.intro-overlay').forEach(element => {
  element.instance = element.instance || new IntroOverlayComponent(element)
}))
