/**
 * Created by LMO on 12/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import {
  EVENT_MUTE_ALL_VIDEOS,
  EVENT_REQUEST_MUTED_STATUS,
  EVENT_UNMUTE_ALL_VIDEOS
} from '../../atoms/sound-button/sound-button'
import { EVENT_REGISTER_CLOSABLE } from '../menu-bar/menu-bar'

export const EVENT_MICRIO_FREEZE = 'event-micrio-freeze'
export const EVENT_MICRIO_GOTO_OVERVIEW = 'event-micrio-goto-overview'
export const EVENT_MICRIO_GOTO_MAIN = 'event-micrio-goto-main'
export const EVENT_MICRIO_GOTO_MARKER = 'event-micrio-goto-marker'
export const EVENT_MICRIO_HIDE = 'event-micrio-hide'
export const EVENT_MICRIO_MARKER_SELECTED = 'event-micrio-marker-selected'
export const EVENT_MICRIO_UNFREEZE = 'event-micrio-unfreeze'
export const EVENT_MICRIO_SHOW = 'event-micrio-show'

async function fetchImageData (imageName) {
  let response

  for (const lang of ['nl', 'en', 'fr']) {
    response = await fetch(`https://b.micr.io/${imageName}/data.${lang}.json`)

    if (response.status === 200) {
      break
    }
  }

  if (response.status !== 200) {
    return
  }

  return response.json()
}

async function buildMap (imageName) {
  const graph = new Map([[imageName, []]])
  const queue = [imageName]
  const connections = []
  while (queue.length) {
    const image = queue.pop()
    const data = await fetchImageData(image)
    if (!data) {
      continue
    }
    for (const marker of (data.markers || [])) {
      const linkedImage = marker.data?.micrioLink?.id
      if (linkedImage) {
        connections.push([image, linkedImage, marker.id])
        if (!graph.has(linkedImage)) {
          graph.set(linkedImage, [])
          queue.push(linkedImage)
        }
      }
    }
  }
  for (const [a, b, c] of connections) {
    graph.get(a).push([b, c])
  }
  return graph
}

function findRoute (map, currentImage, targetImage) {
  if (!map.has(currentImage) || !map.has(targetImage)) {
    return []
  }
  const queue = [[currentImage, []]]
  const seen = new Set([currentImage])
  while (queue.length > 0) {
    const [lastImage, path] = queue.shift()
    if (lastImage === targetImage) {
      return path
    }
    for (const [option, markerId] of map.get(lastImage)) {
      if (!seen.has(option)) {
        seen.add(option)
        queue.push([option, [...path, [markerId, option]]])
      }
    }
  }
  return []
}

function delay (time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

async function navigateToImage (micrio, map, currentImage, imageName) {
  const route = findRoute(map, currentImage, imageName)

  if (!route || !route.length) {
    if (currentImage !== imageName) {
      const navigator = micrio.modules.navigator
      return navigator.goto(imageName)
    }

    return micrio
  }

  for (const [markerId, imageId] of route) {
    const navigator = micrio.modules.navigator
    const view = micrio.modules.markers.getItemById(markerId).view
    await micrio.camera.flyToView(view)
    micrio = await navigator.goto(imageId)
    await delay(500)
  }

  return micrio
}

function createMicrioStyleNode () {
  const node = document.createElement('style')
  node.setAttribute('data-for', 'micrio-main')
  node.innerHTML = [
    'button.micrio-icon.zoom-out { display: none }',
    'button.micrio-icon.zoom-in { display: none }',
    'button.micrio-icon.close { display: none }',
    ':host > .controls > button.zoom-in { display: none }',
    ':host > .controls > button.zoom-out { display: none }',
    ':host > .controls > button.close { display: none }',
    ':host > .controls > button.fullscreen { display: none }'
  ].join(' ')
  return node
}

export default class Micrio extends Component {
  init () {
    this.micrioElement = this.element.querySelector('micr-io')
    this.micrio = null
    this.frozen = false // can we use and interact with micrio?
    this.hidden = false
    this.queue = [] // allows queueing of commands before micrio is loaded
    this.is_muted = false
    this.setupNavigation()

    this.onLoad = async () => {
      const afterPlay = () => {
        if (this.is_muted) {
          this.micrio.muted = true
          this.micrio.video.muted = true
        } else {
          this.micrio.muted = false
          this.micrio.video.muted = false
        }
      }
      // this.micrio = this.micrioElement// .micrio
      this.micrio = this.micrioElement.micrio
      if (this.micrio.video) {
        this.micrio.video.muted = true // this helps Safari
        try {
          await this.micrio.video.play()
        } catch (e) {
          console.log('Not playing possibly: ', e)
        } finally {
          afterPlay()
        }
        this.micrio.video.play().then(afterPlay).catch((reason) => {
          console.log('Not playing possibly: ', reason)
          afterPlay()
          // this.micrio.video.muted = true
          // fireCustomEvent(EVENT_MUTE_ALL_VIDEOS)
        })
      }

      this.processQueue().catch(console.log)
    }

    console.log(this.micrioElement, '<<<', this.element, this.element.querySelector('micr-io'))
    this.micrioElement.addEventListener('load', this.onLoad)

    this.initClosableNavigation()
    this.initCustomStyling()
    this.initCustomMarkerStyling()
    this.initEnableDisableListening()
    this.initHideAndShowListenening()
    this.initGotoMarkerListening()
    this.initMarkerSelectionNotifications()
    this.initMuteListening()
    this.initOverview()
  }

  async setupNavigation () {
    this.routeMap = await buildMap(this.micrioElement.id)
  }

  async changeImage (newImageId) {
    console.log('changing image to', newImageId)
    if (!this.micrio) {
      await this._doMicrioAction(async () => {
        await this.changeImage(newImageId)
      })
      return false
    }

    this.micrio = await navigateToImage(this.micrio, this.routeMap, this.micrio.id, newImageId)

    if (this.micrio.id === newImageId) {
      await this.processQueue()
      return true
    } else {
      console.log('current micrio', this.micrio.id)
      return true
    }
  }

  initClosableNavigation () {
    window.addEventListener(EVENT_MICRIO_MARKER_SELECTED, (event) => {
      if (event.detail && event.detail.custom && event.detail.custom.closable === true) {
        fireCustomEvent(EVENT_REGISTER_CLOSABLE, {
          callback: () => {
            this._doMicrioAction(() => {
              this.changeImage(this.micrioId).catch(console.log)
            })
          }
        })
      }
    })
  }

  initCustomMarkerStyling () {
    // Markers are set each time an image is loaded, so that every image can have custom markers.
    this.micrioElement.addEventListener('load', () => {
      this.micrio.modules.markers.items.forEach((marker) => {
        // const onLoad = () => {
        //   console.log(this.micrio.$current.$data.markers)
        //   this.micrio.$current.$data.markers.forEach((marker) => {
        const imageId = marker.data?.micrioLink?.id

        if (imageId) {
          marker._marker.classList.add(`micrio-marker--${imageId}`)
        }
      })
    })
  }

  initCustomStyling () {
    this._doMicrioAction(() => {
      this.micrioElement.shadowRoot.appendChild(createMicrioStyleNode())
    })
  }

  initEnableDisableListening () {
    window.addEventListener(EVENT_MICRIO_UNFREEZE, () => {
      this._doMicrioAction(() => {
        this.frozen = false
        this.micrio.camera.hook()
      })
    })

    window.addEventListener(EVENT_MICRIO_FREEZE, () => {
      this._doMicrioAction(() => {
        this.frozen = true
        this.micrio.camera.unhook()
      })
    })
  }

  initGotoMarkerListening () {
    this._doMicrioAction(() => {
      window.addEventListener(EVENT_MICRIO_GOTO_MARKER, async (event) => {
        const imageId = event.detail.imageId
        const markerId = event.detail.id
        this.micrio = await navigateToImage(this.micrio, this.routeMap, this.micrio.id, imageId)
        this.micrio.camera.flyToView(this.micrio.modules.markers.getItemById(markerId).view).catch(console.log)
      })
    })
  }

  initHideAndShowListenening () {
    window.addEventListener(EVENT_MICRIO_HIDE, () => this.hide())
    window.addEventListener(EVENT_MICRIO_SHOW, () => this.show())

    if (window.micrioPleaseHide) {
      delete window.micrioPleaseHide
      this.hide()
    }

    this._doMicrioAction(() => {
      if (this.micrio.video) {
        this.micrio.video.addEventListener('timeupdate', () => {
          if (this.hidden) {
            this.micrio.hide()
          }
        })
      }
    })
  }

  initMarkerSelectionNotifications () {
    this._doMicrioAction(() => {
      this.micrioElement.addEventListener('marker-open', (event) => {
        fireCustomEvent(EVENT_MICRIO_MARKER_SELECTED, event.detail)
      })
    })
  }

  initMuteListening () {
    window.addEventListener(EVENT_MUTE_ALL_VIDEOS, () => {
      this._doMicrioAction(() => {
        if (!this.is_muted) {
          this.micrio.muted = true
          this.is_muted = true
        }
      })
    })
    window.addEventListener(EVENT_UNMUTE_ALL_VIDEOS, () => {
      this._doMicrioAction(() => {
        if (this.is_muted) {
          this.micrio.muted = false
          this.is_muted = false
        }
      })
    })
    fireCustomEvent(EVENT_REQUEST_MUTED_STATUS)
  }

  initOverview () {
    this.micrioId = this.micrioElement.getAttribute('id')
    this.overviewImageId = this.element.getAttribute('data-micrio-overview-id')

    const openOverview = () => {
      // this.element.classList.add('micrio--on-overview')
      this.changeImage(this.overviewImageId)
      // fireCustomEvent(EVENT_REGISTER_CLOSABLE, { callback: closeOverview })
    }

    // const closeOverview = () => {
    //   this.element.classList.remove('micrio--on-overview')
    //   this.changeImage(this.micrioId)
    // }

    if (this.micrioId && this.overviewImageId) {
      window.addEventListener(EVENT_MICRIO_GOTO_OVERVIEW, () => {
        if (!this.frozen) {
          if (this.beforeOverviewImage) {
            const goto = this.beforeOverviewImage
            this.beforeOverviewImage = null
            this._doMicrioAction(() => this.changeImage(goto))
          } else {
            this.beforeOverviewImage = this.micrio.id
            this._doMicrioAction(openOverview)
          }
        }
      })

      // window.addEventListener(EVENT_MICRIO_GOTO_MAIN, () => {
      //   if (!this.frozen) {
      //     this._doMicrioAction(closeOverview)
      //   }
      // })
    }
  }

  async hide () {
    await this._doMicrioAction(async () => {
      this.hidden = true
      // await this.micrio.hide()
      // await delay(100)
      // await this.micrio.hide()
    })
  }

  async processQueue () {
    // We can not guarantee that micrio isn't loading, so therefore we queue when it is loading
    while (this.micrio && this.queue.length) {
      await this.queue.shift()()
    }
  }

  show () {
    this._doMicrioAction(async () => {
      this.hidden = false
      // this.micrio.show()
      await this.onLoad()
    })
  }

  async _doMicrioAction (callback) {
    console.log('Received micro action!')
    this.queue.push(callback)
    await this.processQueue()
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.website-micrio').forEach(
    element => { element.instance = element.instance || new Micrio(element) })
)
