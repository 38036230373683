/**
 * Created by LMO on 14/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */

import Swiper from 'swiper'

import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_INTRO_OVERLAY_HIDE } from '../intro-overlay/intro-overlay'

export const EVENT_OPEN_VALUE_CHAIN = 'event-open-value-chain'
export const EVENT_VALUE_CHAIN_OPENED = 'event-value-chain-opened'
export const EVENT_VALUE_CHAIN_CLOSED = 'event-value-chain-closed'

export default class ValueChain extends Component {
  init () {
    this.isOpen = this.element.classList.contains('value-chain--open')
    this.swiperInited = false

    this.initHomeCardListening()
    this.initOpenListening()
    this.initSwiper()
    this.initValueChainCloser()
    this.initHashtagParsing()
  }

  close () {
    this.element.classList.remove('value-chain--open')
    fireCustomEvent(EVENT_VALUE_CHAIN_CLOSED)
    this.isOpen = false
  }

  initHashtagParsing () {
    if (!this.swiperInited) {
      this.initSwiper()
    }

    if (window.location.hash.startsWith('#value-chain')) {
      if (window.location.hash.startsWith('#value-chain++')) {
        const [_, target] = (window.location.hash + '++').split('++', 2) /* eslint-disable-line no-unused-vars */
        const slides = Array.from(this.element.querySelector('.swiper-wrapper').children)
        const element = slides.reduce((p, n) => (p.getAttribute('data-anchor')) === target ? p : n)
        const swiperIndex = slides.indexOf(element)
        this.swiper.slideTo(swiperIndex || 0)
      }

      fireCustomEvent(EVENT_INTRO_OVERLAY_HIDE) // in this case we don't want an intro overlay in our way
      this.open()
    }
  }

  initHomeCardListening () {
    const clickElem = this.element.querySelector('.value-chain__home-card .value-chain-card__hit-target')
    if (!clickElem) {
      return
    }
    clickElem.addEventListener('click', (event) => {
      event.preventDefault()
      this.close()
    })
  }

  initOpenListening () {
    window.addEventListener(EVENT_OPEN_VALUE_CHAIN, () => {
      if (!this.isOpen) {
        this.open()
      }
    })
  }

  initSwiper () {
    this.swiper = new Swiper(this.element.querySelector('.swiper-container'), {
      mousewheel: true,
      resistance: true,
      resistanceRatio: 0,
      roundLengths: true,
      slidesPerView: 'auto'
    })
    this.swiperInited = true
  }

  initValueChainCloser () {
    const closer = this.element.querySelector('.value-chain__closer')
    closer.addEventListener('click', (event) => {
      event.preventDefault()
      this.close()
    })
  }

  open () {
    this.element.classList.add('value-chain--open')
    fireCustomEvent(EVENT_VALUE_CHAIN_OPENED)
    this.isOpen = true
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.value-chain').forEach(
    element => { element.instance = element.instance || new ValueChain(element) })
)
