
require('../../components/atoms/icon-button/icon-button.js')
require('../../components/atoms/micrio-minimap/micrio-minimap.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/sound-button/sound-button.js')
require('../../components/atoms/video-chapter/video-chapter.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/video/video.js')
require('../../components/molecules/marquee-bar/marquee-bar.js')
require('../../components/molecules/scroll-down/scroll-down.js')
require('../../components/molecules/value-chain-card/value-chain-card.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/chaptered-video/chaptered-video.js')
require('../../components/organisms/menu-bar/menu-bar.js')
require('../../components/organisms/micrio/micrio.js')
require('../../components/organisms/scroll-down/scroll-down.js')
require('../../components/structures/intro-overlay/intro-overlay.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/scroll-down-struct/scroll-down-struct.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/structures/value-chain-opener/value-chain-opener.js')
require('../../components/structures/value-chain/value-chain.js')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 500))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

// Import smooth scroll (~35kB) - for Safari and Edge
if (window.navigator.userAgent.indexOf('Safari') !== -1 || window.navigator.userAgent.indexOf('Edge') !== -1) {
  if (!('scrollBehavior' in document.documentElement.style)) {
    const smoothscroll = require('smoothscroll-polyfill')
    smoothscroll.polyfill()

    // Auto-adjust anchors to smooth scroll, because css property is not supported
    document.addEventListener('DOMContentLoaded', () => {
      const anchorLinks = Array.from(document.querySelectorAll('[href^="#"]'))
      anchorLinks.forEach((anchorLink) => {
        anchorLink.addEventListener('click', (event) => {
          const matchingElement = document.querySelector(anchorLink.getAttribute('href'))
          if (matchingElement) {
            event.preventDefault()
            matchingElement.scrollIntoView({ behavior: 'smooth' })
          }
        })
      })
    })
  }

  // Web animations polyfill ~(50kB) - for Safari and Edge
  // About 50kb raw, so only use if you need it.
  // const x = import('web-animations-js')
}

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')
