/**
 * Created by LMO on 28/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */

import { Marquee } from 'dynamic-marquee'

import Component from '../../../assets/scripts/modules/component'

export const EVENT_STOP_MARQUEE = 'event-stop-marquee'
export const SPEED = -80 // px/s
export const INTER_SPACE = 180 // px

export default class MarqueeBar extends Component {
  init () {
    this.marquee = null

    this.initMarquee()
    this.initStopMarqueeListening()
  }

  initMarquee () {
    const template = this.element.querySelector('.marquee-bar__item')
    this.element.removeChild(template)
    const marquee = new Marquee(this.element)
    const newItem = () => template.cloneNode(true)
    marquee.setRate(SPEED)
    marquee.onItemRequired(() => {
      setTimeout(() => marquee.appendItem(newItem()), Math.abs(INTER_SPACE / SPEED) * 1000)
    })
    marquee.appendItem(newItem())
    this.marquee = marquee
  }

  initStopMarqueeListening () {
    window.addEventListener(EVENT_STOP_MARQUEE, () => {
      this.marquee.setRate(0)
    })
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.marquee-bar').forEach(
    element => { element.instance = element.instance || new MarqueeBar(element) }
  )
)
