
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { EVENT_OPEN_VALUE_CHAIN } from '../value-chain/value-chain'

export const EVENT_MENU_OVERLAY_OPEN = 'event-menu-overlay-open'

export default class MenuOverlayComponent extends Component {
  init () {
    this.initCloseButton()
    this.initOpenListening()
    this.initCloseOnBackgroundClick()
    this.initValueChainFix()
  }

  close () {
    this.element.classList.remove('menu-overlay--visible')
  }

  initCloseButton () {
    const button = this.element.querySelector('.menu-overlay__closer')
    button.addEventListener('click', () => this.close())
  }

  initCloseOnBackgroundClick () {
    const main = this.element.querySelector('.menu-overlay__main')
    this.element.addEventListener('click', () => this.close())
    main.addEventListener('click', (event) => event.stopPropagation())
  }

  initOpenListening () {
    window.addEventListener(EVENT_MENU_OVERLAY_OPEN, () => this.open())
  }

  initValueChainFix () {
    const isRequired = !!document.querySelector('.value-chain')
    const valueChainLinks = Array.from(this.element.querySelectorAll('[href*="#value-chain"]'))

    if (isRequired) {
      valueChainLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
          event.preventDefault()
          this.close()
          fireCustomEvent(EVENT_OPEN_VALUE_CHAIN)
        })
      })
    }
  }

  open () {
    this.element.classList.add('menu-overlay--visible')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-overlay').forEach(element => {
  element.instance = element.instance || new MenuOverlayComponent(element)
}))
