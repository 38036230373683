
import Component from '../../../assets/scripts/modules/component'
class ValueChainCardComponent extends Component {
  init () {
    this.video = this.element.querySelector('.video__video')
    this.hitTarget = this.element.querySelector('.value-chain-card__hit-target')

    if (this.video && this.hitTarget) {
      this.hitTarget.addEventListener('mouseover', this.onHitTargetMouseOver.bind(this))
      this.hitTarget.addEventListener('mouseleave', this.onHitTargetMouseLeave.bind(this))
    }
  }

  onHitTargetMouseOver () {
    this.video.play()
  }

  onHitTargetMouseLeave () {
    this.video.pause()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.value-chain-card').forEach(element => {
  element.instance = element.instance || new ValueChainCardComponent(element)
}))
