
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export const EVENT_MUTE_ALL_VIDEOS = 'event-video-muted'
export const EVENT_UNMUTE_ALL_VIDEOS = 'event-video-unmuted'
export const EVENT_REQUEST_MUTED_STATUS = 'event-request-muted-status'
export const LOCAL_STORAGE_KEY = 'WHITE_CUBE_SOUND_MUTED'

export default class SoundButtonComponent extends Component {
  init () {
    this.isMuted = false
    this.buttonContainer = this.element
    this.videos = document.querySelectorAll('video')
    this.initButton()
    this.initEventListening()
    this.initFromStorage()
  }

  initButton () {
    const onButton = this.element.querySelector('.sound-button__on')
    const offButton = this.element.querySelector('.sound-button__off')
    onButton.addEventListener('click', () => this.toggleAudio())
    offButton.addEventListener('click', () => this.toggleAudio())
  }

  initEventListening () {
    // We're not necessarily the only mute button
    window.addEventListener(EVENT_MUTE_ALL_VIDEOS, () => {
      this.buttonContainer.classList.add('sound-button__muted')
      this.isMuted = true
      window.localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
    })
    window.addEventListener(EVENT_UNMUTE_ALL_VIDEOS, () => {
      this.buttonContainer.classList.remove('sound-button__muted')
      this.isMuted = false
      window.localStorage.setItem(LOCAL_STORAGE_KEY, 'false')
    })
    // Another class can request the muted status, so that it properly adjusts it muted status
    window.addEventListener(EVENT_REQUEST_MUTED_STATUS, () => {
      if (this.isMuted) {
        fireCustomEvent(EVENT_MUTE_ALL_VIDEOS)
      } else {
        fireCustomEvent(EVENT_UNMUTE_ALL_VIDEOS)
      }
    })
  }

  initFromStorage () {
    const muted = window.localStorage.getItem(LOCAL_STORAGE_KEY) === 'true' || false
    fireCustomEvent(muted ? EVENT_MUTE_ALL_VIDEOS : EVENT_UNMUTE_ALL_VIDEOS)
  }

  toggleAudio () {
    if (this.isMuted) {
      fireCustomEvent(EVENT_UNMUTE_ALL_VIDEOS)
    } else {
      fireCustomEvent(EVENT_MUTE_ALL_VIDEOS)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.sound-button').forEach(element => {
  element.instance = element.instance || new SoundButtonComponent(element)
}))
