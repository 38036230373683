/**
 * Created by LMO on 06/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */
import Component from '../../../assets/scripts/modules/component'

export default class ScrollDownStructComponent extends Component {
  init () {
    const hitTargetElement = this.element.querySelector('.scroll-down-struct__hit-target')

    hitTargetElement.addEventListener('click', (event) => {
      event.preventDefault()
      window.scrollTo({ behavior: 'smooth', top: this.getTargetPosition() })
    })
  }

  getTargetPosition () {
    return window.innerHeight
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.scroll-down-struct').forEach(
    element => { element.instance = element.instance || new ScrollDownStructComponent(element) })
)
