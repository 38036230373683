/**
 * Created by LMO on 12/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import {
  EVENT_MICRIO_GOTO_MAIN,
  EVENT_MICRIO_GOTO_MARKER,
  EVENT_MICRIO_GOTO_OVERVIEW,
  EVENT_MICRIO_MARKER_SELECTED
} from '../../organisms/micrio/micrio'

export default class MicrioMinimap extends Component {
  init () {
    this.initCloseOverviewListening()
    this.initMinimapChangePerMarker()
    this.initMinimapClickableMarkers()
    this.initOpenOverviewListening()
  }

  initCloseOverviewListening () {
    const closeElement = this.element.querySelector('.micrio-minimap__close-overview')

    if (closeElement) {
      closeElement.addEventListener('click', (event) => {
        event.preventDefault()

        // fireCustomEvent(EVENT_MICRIO_GOTO_MAIN)
      })
    }
  }

  initMinimapChangePerMarker () {
    const markers = Array.from(this.element.querySelectorAll('.micrio-minimap-marker'))
    const markersById = markers.reduce((obj, item) => {
      obj[item.getAttribute('data-marker-id')] = item
      return obj
    }, {})
    let activeMarker = null

    window.addEventListener(EVENT_MICRIO_MARKER_SELECTED, (event) => {
      const marker = event.detail

      if (marker.id in markersById) {
        if (activeMarker) {
          activeMarker.classList.remove('micrio-minimap-marker--active')
        }

        activeMarker = markersById[marker.id]
        activeMarker.classList.add('micrio-minimap-marker--active')
      }
    })
  }

  initMinimapClickableMarkers () {
    const markers = Array.from(this.element.querySelectorAll('.micrio-minimap-marker'))

    markers.forEach((marker) => {
      const id = marker.getAttribute('data-marker-id')
      const imageId = marker.getAttribute('data-image-id')

      if (id) {
        marker.addEventListener('click', (event) => {
          event.preventDefault()
          fireCustomEvent(EVENT_MICRIO_GOTO_MARKER, { id, imageId })
        })
      }
    })
  }

  initOpenOverviewListening () {
    const openElement = this.element.querySelector('.micrio-minimap__open-overview')

    if (openElement) {
      openElement.addEventListener('click', (event) => {
        event.preventDefault()
        console.log('firing event')

        fireCustomEvent(EVENT_MICRIO_GOTO_OVERVIEW)
      })
    }
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.micrio-minimap').forEach(
    element => { element.instance = element.instance || new MicrioMinimap(element) })
)
