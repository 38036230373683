/**
 * Created by LMO on 11/02/2020.
 * (c) Fabrique - Merken, Design & Interactie
 */
import Component from '../../../assets/scripts/modules/component'
import findParentByClassName from '../../../assets/scripts/utilities/find-parent-by-classname'

export const EVENT_ICON_BUTTON_HOOK_WHEN_CHILD = 'event-hook-when-child'

export default class IconButton extends Component {
  init () {
    if (this.element.classList.contains('icon-button--timed')) {
      this.initTimedIconButton()
    }
  }

  connectToInstance (eventName, instance) {
    this.hookedInstance = instance

    window.addEventListener(eventName, (event) => {
      const { value, instance: eventInstance } = event.detail

      if (this.hookedInstance === eventInstance) {
        this.timerElement.style.strokeDashoffset = Math.round(2 * Math.PI * this.timerRadius * (1 - value))
      }
    })
  }

  initTimedIconButton () {
    this.timerElement = document.querySelector('.icon-button__circle')
    this.timerRadius = Number(this.timerElement.getAttribute('r') || 1)
    this.hookedInstance = null

    window.addEventListener(EVENT_ICON_BUTTON_HOOK_WHEN_CHILD, (event) => {
      const { className, eventName, instance } = event.detail
      const maybeParent = findParentByClassName(this.element, className)

      if (maybeParent && maybeParent.instance === instance && this.hookedInstance !== instance) {
        this.connectToInstance(eventName, instance)
      }
    })
  }
}

window.addEventListener(
  'init-load',
  () => document.querySelectorAll('.icon-button--timed').forEach(// NOTE the --timed!
    element => { element.instance = element.instance || new IconButton(element) })
)
